<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "รุ่นรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, btnComponent, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "รุ่นรถ",
      items: [
        {
          text: "ข้อมูลหลัก",
          active: true,
        },
        {
          text: "ศูนย์บริการ",
          active: true,
        },
        {
          text: "รุ่นรถ",
          active: false,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      filter: {
        brand: "",
        model: "",
        modelYear: "",
      },
      userBranch: "",
      filterOn: [],
      rowsVehicleBrand: [],
      modelId: "",
      rows: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "brandNameEn",
          sortable: true,
          label: "ยี่ห้อ",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่อรุ่น",
        },
        {
          key: "modelYear",
          sortable: true,
          label: "ปีรุ่น",
        },
        {
          key: "branchNameTh",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "action",
          sortable: false,
          label: appConfig.action,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
  },
  created() {
    this.getDataVehicleBrand();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-model", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]": this.userBranchId,
            brandId: this.filter.brand != null ? this.filter.brand.brandId : undefined,
            nameEn: this.filter.model,
            modelYear: this.filter.modelYear,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          // this.rowsTest.to = response.data.to;
          // this.rowsTest.from = response.data.from;
          // this.rowsTest.total = response.data.total;
          // console.log(this.rowsTest.total);
          // console.log(this.rowsTest);
        })
        .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows;
    },
    deleteVehicle(modelId) {
      this.modelId = modelId;
      this.overlayFlag = true;
      useNetw
        .delete("api/vehicle-model/delete", {
          data: {
            modelId: this.modelId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      this.getData();
    },
    getDataVehicleBrand: function() {
      this.isLoading = true;
      useNetw
        .get("api/master/vehicle/brands", {})
        .then((response) => {
          this.rowsVehicleBrand = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    alertDelete(modelId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ลบข้อมูล?",
          text: "ต้องการลบรุ่นรถนี้หรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteVehicle(modelId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn m-2 float-end mt-4"
                    variant="primary"
                    type="submit"
                    :to="{ name: 'vehiclemodelform' }"
                  >
                    <i class="uil-plus"></i>
                    สร้าง
                  </b-button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                      <div class="mb-3">
                        ยี่ห้อ:
                        <multiselect
                          :options="rowsVehicleBrand"
                          v-model="filter.brand"
                          :close-on-select="true"
                          :internal-search="true"
                          open-direction="bottom"
                          :show-labels="false"
                          label="nameEn"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                      <div class="mb-3">
                        รุ่น:
                        <b-form-input
                          v-model="filter.model"
                          type="search"
                          class="form-control"
                          @keyup.enter="handleSearch"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                      <div class="mb-3">
                        ปีรุ่น:
                        <b-form-input
                          v-model="filter.modelYear"
                          type="search"
                          class="form-control"
                          @keyup.enter="handleSearch"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-2 mt-4 mb-2">
                      &nbsp;
                      <btnComponent @click="handleSearch" changeStyle="search">
                      </btnComponent>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="rows"
                  :fields="fields"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  responsive="sm"
                  ref="selectableTable"
                  selectable
                  @row-selected="onRowSelected"
                >
                  <template #cell(index)="rows">
                    {{ rows.index + 1 }}
                  </template>
                  <template v-slot:cell(action)="rows">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <router-link
                          href="javascript:void(0);"
                          class="px-2 text-primary"
                          v-b-tooltip.hover
                          :to="{
                            name: 'vehiclemodelformdetail',
                            params: { modelId: rows.item.modelId },
                          }"
                          title="Edit"
                        >
                          <i class="uil uil-pen font-size-18"></i>
                        </router-link>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript:void(0);"
                          class="px-2 text-danger"
                          v-b-tooltip.hover
                          title="Delete"
                          @click="alertDelete(rows.item.modelId)"
                        >
                          <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                      </li>
                    </ul>
                  </template>
                </b-table>
                <!-- <p class="float-end">
                  <b-button class="m-2" size="sm" @click="selectAllRows"
                    >Select all</b-button
                  >
                  <b-button class="m-2" size="sm" @click="clearSelected"
                    >Clear selected</b-button
                  >
                </p> -->
              </div>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rows.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
